import React, { useState } from 'react';
import { Document } from '../../types';
import Documents from './documents';

interface DocumentsProps {
  heading: string;
  documents: Document[];
}

const DocumentsWithHeading = ({ heading, documents }: DocumentsProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => setIsOpen(!isOpen);

  return (
    <div className="flex flex-col gap-4">
      <button
        type="button"
        className="flex justify-between items-center px-0 text-base lg:text-xl font-semibold text-black"
        onClick={toggleAccordion}
      >
        {heading}
        <span
          className={`transform transition-transform ${
            isOpen ? 'rotate-180' : ''
          }`}
        >
          ▼
        </span>
      </button>

      {isOpen && <Documents documents={documents} />}
    </div>
  );
};

export default DocumentsWithHeading;
