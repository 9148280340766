import * as React from 'react';
import { Document } from '../../types';

interface DocumentsProps {
  documents: Document[];
  isBold?: boolean;
}

const Documents = ({ documents, isBold = false }: DocumentsProps) => {
  return (
    <div className="flex flex-col gap-[0.625rem]">
      {documents.map(({ link, title }) => (
        <React.Fragment key={link}>
          <a
            href={link}
            target="_blank"
            className={`flex justify-between items-center gap-2 no-underline text-black text-base md:text-lg ${
              isBold ? 'font-semibold' : ''
            }`}
            rel="noreferrer"
          >
            <p>{title}</p>
            <div className="min-w-[20px] min-h-[20px] bg-[url('../images/document.svg')] bg-center bg-no-repeat" />
          </a>
          <hr className="last:hidden" />
        </React.Fragment>
      ))}
    </div>
  );
};

export default Documents;
